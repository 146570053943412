import {
  differenceInCalendarDays,
  startOfWeek,
  isAfter,
  startOfMonth,
} from "date-fns"

export const convertTimestampToString = (timestamp) => {
  if (!timestamp) {
    return ""
  }
  return timestamp.toDate().toISOString() // Converts to an ISO 8601 formatted string
}

const DAYS_IN_WEEK = 7
const DAYS_IN_MONTH = 30

export const isChoreComplete = (chore) => {
  if (!chore.last) {
    return false
  }

  const today = new Date()
  const lastDate = new Date(Date.parse(chore.last))
  if (isNaN(lastDate)) {
    console.log("Invalid date")
    return false
  }
  const daysSinceLastCompleted = differenceInCalendarDays(today, lastDate)
  const frequency = chore.frequency ? chore.frequency.toLowerCase() : "daily" // Default to daily if no frequency

  switch (frequency) {
    case "daily":
      return daysSinceLastCompleted === 0
    case "weekly": {
      const startOfCurrentWeek = startOfWeek(today, { weekStartsOn: 0 }) // Sunday as start
      return (
        isAfter(lastDate, startOfCurrentWeek) ||
        differenceInCalendarDays(lastDate, startOfCurrentWeek) === 0
      )
    }
    case "monthly":
      const startOfCurrentMonth = startOfMonth(today)
      return (
        isAfter(lastDate, startOfCurrentMonth) ||
        differenceInCalendarDays(lastDate, startOfCurrentMonth) === 0
      )
    default:
      return false
  }
}
