import React, { Fragment } from 'react';
import { Routes, Route, Navigate } from "react-router-dom";

import FtamHomePage from '../views/home-pages/FTAM_HomePage';

//All Home Page Routes
import ArtificialIntelligence from '../views/home-pages/ArtificialIntelligence';
import HomePage from '../views/home-pages/HomePage';
import DataScience from '../views/home-pages/DataScience';
import ChatBoot from '../views/home-pages/ChatBoot';
import MachineLearning from '../views/home-pages/MachineLearning';

//All Inner Page Routes

//All DropDown Page Routes

//All About Page Routes
import AboutMe from '../views/inner-pages/pages/about-us/AboutMe';

//All Service Page Routes
import ServicesOne from '../views/inner-pages/pages/services/ServicesOne';
import ServicesTwo from '../views/inner-pages/pages/services/ServicesTwo';
import ServicesDetails from '../views/inner-pages/pages/services/ServicesDetails';

//All Team Page Routes
import TeamMember from '../views/inner-pages/pages/team/TeamMember';
import TeamDetails from '../views/inner-pages/pages/team/TeamDetails';


// Testimonial Page Routes
import Testimonial from '../views/inner-pages/pages/Testimonial';
import OurPricing from '../views/inner-pages/pages/OurPricing';
import FAQ from '../views/inner-pages/pages/FAQ';
import Error from '../views/inner-pages/pages/Error';

// All Portfolio Page Routes
import Portfolio3Column from '../views/inner-pages/portfolio/Portfolio3Column';
import Portfolio2Column from '../views/inner-pages/portfolio/Portfolio2Column';
import PortfolioMasonry from '../views/inner-pages/portfolio/PortfolioMasonry';
import PortfolioSingle from '../views/inner-pages/portfolio/PortfolioSingle';

// All Blog Page Routes
import GridWithSidebar from '../views/inner-pages/blog/GridWithSidebar';
import BlogMasonry from '../views/inner-pages/blog/BlogMasonry';
import BlogStandard from '../views/inner-pages/blog/BlogStandard';
import BlogDetails from '../views/inner-pages/blog/BlogDetails';
import BlogExample from '../views/inner-pages/blog/BlogExample';

// Contact Page Routes
import Contact from '../views/inner-pages/contact/Contact';

// Not Found Page
import NotFound from "../views/NotFound";
import SignUpPage from '../views/SignUpPage';
import ComingSoon from '../views/ComingSoon';
import { SignInPage } from '../views/SignInPage';

import SiblingNewsletterPage from '../views/inner-pages/blog/SiblingNewsletterPage';
import MonthlyNewslettersPage from '../views/inner-pages/blog/MonthlyNewslettersPage';
import YearlyNewsletterPage from '../views/inner-pages/blog/YearlyNewsletterPage';
import AllNewslettersPage from '../views/inner-pages/blog/AllNewslettersPage';
import Survey from '../views/Survey/Survey';
import VideoList from '../views/VideoList';
import ChoresView from '../views/inner-pages/ChoresView/ChoresView';
import Countdown from '../views/Countdown';
import AdvenchoreStoryPage from '../views/inner-pages/StoryView/AdvenchoreStoryPage';
import StoryView from '../views/inner-pages/StoryView/StoryView';
import FamilyTimeline from "../views/inner-pages/TimelineView/FamilyTimeline"
import ImageGenerator from "../views/inner-pages/ImageGenerator/ImageGenerator"
import PictureViewingPage from "../views/inner-pages/pictures/PictureViewingPage"
import ComparisonPage from "../views/inner-pages/pictures/ComparisonPage"
import FamilyAddressTable from "../views/inner-pages/addresses/FamilyAddressTable"
import PodcastsView from "../views/inner-pages/PodcastsView/PodcastsView"
import AudioPlayer from "../views/inner-pages/PodcastsView/AudioPlayer"
import BedtimeStoriesView from "../views/inner-pages/PodcastsView/BedtimeStoriesView"
import BedtimeUtils from "../views/BedtimeUtils"
import ExpensesPage from "../views/inner-pages/Expenses/ExpensesPage"
import AnimationDemo from "../views/inner-pages/animation/AnimationDemo"
import ConfettiTest from "../views/inner-pages/animation/ConfettiTest"
import ProtectedRoute from "./ProtectedRoute"

const AppRouter = () => {
  return (
    <Fragment>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/signin" element={<SignInPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route
          path="/artificial-intelligence"
          element={<ArtificialIntelligence />}
        />
        <Route path="/data-science" element={<DataScience />} />
        <Route path="/chatboot" element={<ChatBoot />} />
        <Route path="/machine-learning" element={<MachineLearning />} />

        <Route path="/about-me" element={<AboutMe />} />

        <Route path="/leah" element={<Survey />} />
        <Route
          path="/chores"
          element={
            <ProtectedRoute>
              <ChoresView />
            </ProtectedRoute>
          }
        />
        <Route
          path="/timeline"
          element={
            <ProtectedRoute>
              <FamilyTimeline />
            </ProtectedRoute>
          }
        />

        <Route path="/service-one" element={<ServicesOne />} />
        <Route path="/service-two" element={<ServicesTwo />} />
        <Route path="/service-details" element={<ServicesDetails />} />

        <Route path="/team-member" element={<TeamMember />} />
        <Route path="/team-details" element={<TeamDetails />} />

        <Route path="/testimonial" element={<Testimonial />} />
        <Route path="/price" element={<OurPricing />} />
        <Route path="/faq" element={<ComingSoon />} />
        {/* <Route path="/faq" element={<FAQ />} /> */}
        <Route path="/error" element={<Error />} />

        <Route path="/portfolio-3" element={<Portfolio3Column />} />
        <Route path="/portfolio-2" element={<Portfolio2Column />} />
        <Route path="/portfolio-masonry" element={<PortfolioMasonry />} />
        <Route path="/portfolio-single" element={<PortfolioSingle />} />
        <Route path="/portfolio" element={<Portfolio2Column />} />

        {/* <Route path="/blog-grid" element={<GridLayout />} /> */}
        <Route path="/blog-sidebar" element={<GridWithSidebar />} />
        <Route path="/blog-masonry" element={<BlogMasonry />} />
        <Route path="/blog-standard" element={<BlogStandard />} />
        <Route path="/blog-details" element={<BlogDetails />} />
        <Route path="/blog-example" element={<BlogExample />} />
        {/* <Route path="/blog-future" element={<BlogFutureDynamic />} /> */}
        <Route exact path="/newsletter/" element={<AllNewslettersPage />} />
        <Route
          exact
          path="/newsletter/:year/"
          element={<YearlyNewsletterPage />}
        />
        <Route
          exact
          path="/newsletter/:year/:month/"
          element={<MonthlyNewslettersPage />}
        />
        <Route
          exact
          path="/newsletter/:year/:month/:sibling"
          element={<SiblingNewsletterPage />}
        />

        <Route path="/contact" element={<Contact />} />

        <Route path="*" element={<NotFound />} />

        <Route path="/picture-sorting" element={<ComingSoon />} />
        <Route path="/photo-journal" element={<ComingSoon />} />
        {/* <Route path="/come-follow-me" element={<VideoList />} /> */}
        <Route
          path="/advenchore"
          element={
            <ProtectedRoute>
              <AdvenchoreStoryPage />
            </ProtectedRoute>
          }
        />
        <Route path="/retirement" element={<Countdown />} />
        <Route
          path="/viewPictures"
          element={
            <ProtectedRoute>
              <PictureViewingPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/addresses"
          element={
            <ProtectedRoute>
              <FamilyAddressTable />
            </ProtectedRoute>
          }
        />
        <Route
          path="/bedtime-stories"
          element={
            <ProtectedRoute>
              <BedtimeStoriesView />
            </ProtectedRoute>
          }
        />
        <Route
          path="/utils"
          element={
            <ProtectedRoute>
              <BedtimeUtils />
            </ProtectedRoute>
          }
        />
        <Route path="/ftam" element={<FtamHomePage />} />
        <Route
          path="/expenses"
          element={
            <ProtectedRoute>
              <ExpensesPage />
            </ProtectedRoute>
          }
        />
        <Route path="/animation" element={<AnimationDemo />} />
        <Route path="/confetti" element={<ConfettiTest />} />
      </Routes>
    </Fragment>
  )
}

export default AppRouter